<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Chips">
                    <template v-slot:description>
                        <div>
                            <div>import { HbChip }  from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">
            <template v-slot:title>
                Chip Examples + Code
            </template>

            <hb-form label="Default">
                <hb-chip
                    v-for="(item, i) in wizards"
                    :key="'default_chip_' + i"
                    @chip-click="handleChipClick()"
                >
                    {{ item }}
                </hb-chip>
            </hb-form>

            <hb-form
                label="Editable"
                description="Set 'editable' prop to turn on delete 'x' icon"
            >
                <hb-chip
                    v-for="(item, i) in wizards"
                    :key="'editable_chip_' + i"
                    editable
                    @chip-click="handleChipClick()"
                    @click="handleClick()"
                >
                    {{ item }}
                </hb-chip>
            </hb-form>

            <hb-form
                label="Chips with Menu on Click + Expandability"
                description="Set 'pointer' prop to turn on the pointer cursor on hover"
            >
                <v-row no-gutters v-if="exampleAttachments.length > 2 && !showMoreAttachments" style="margin-bottom:-10px;">
                    <hb-menu v-for="(attachment, i) in exampleAttachments.slice(0, 2)" :key="'attachment' + i">
                        <template v-slot:menu-activator>
                            <hb-chip pointer prepend-icon="mdi-paperclip">
                                {{ attachment.name }}
                            </hb-chip>
                        </template>

                        <v-list>

                            <v-list-item :href="attachment.url" target="_blank">
                                <v-list-item-title>View / Print</v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="attachment.url" download>
                                <v-list-item-title>
                                    Download
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="saveFileToDocuments()">
                                <v-list-item-title>Save to Documents</v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </hb-menu>
                    <hb-chip pointer @chip-click="showMoreAttachments = true">
                        + {{ exampleAttachments.length - 2 }} more
                    </hb-chip>
                </v-row>
                <v-row no-gutters v-else style="margin-bottom:-10px;">
                    <hb-menu v-for="(attachment, i) in exampleAttachments" :key="'attachment_expanded' + i">
                        <template v-slot:menu-activator>
                            <hb-chip pointer prepend-icon="mdi-paperclip">
                                {{ attachment.name }}
                            </hb-chip>
                        </template>

                        <v-list>

                            <v-list-item :href="attachment.url" target="_blank">
                                <v-list-item-title>View / Print</v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="attachment.url" download>
                                <v-list-item-title>Download</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="saveFileToDocuments()">
                                <v-list-item-title>Save to Documents</v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </hb-menu>
                </v-row>
            </hb-form>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-form label="Default">
    &lt;hb-chip
        v-for="(item, i) in wizards"
        :key="'default_chip_' + i"
        @chip-click="handleChipClick()"
    >
        <span v-pre>{{ item }}</span>
    &lt;/hb-chip>
&lt;/hb-form>

&lt;hb-form
    label="Editable"
    description="Set 'editable' prop to turn on delete 'x' icon"
>
    &lt;hb-chip
        v-for="(item, i) in wizards"
        :key="'editable_chip_' + i"
        editable
        @chip-click="handleChipClick()"
        @click="handleClick()"
    >
        <span v-pre>{{ item }}</span>
    &lt;/hb-chip>
&lt;/hb-form>

&lt;hb-form
    label="Chips with Menu on Click + Expandability"
    description="Set 'pointer' prop to turn on the pointer cursor on hover"
>
    &lt;v-row no-gutters v-if="exampleAttachments.length > 2 &amp;&amp; !showMoreAttachments" style="margin-bottom:-10px;">
        &lt;hb-menu v-for="(attachment, i) in exampleAttachments.slice(0, 2)" :key="'attachment' + i">
            &lt;template v-slot:menu-activator>
                &lt;hb-chip pointer prepend-icon="mdi-paperclip">
                    <span v-pre>{{ attachment.name }}</span>
                &lt;/hb-chip>
            &lt;/template>

            &lt;v-list>

                &lt;v-list-item :href="attachment.url" target="_blank">
                    &lt;v-list-item-title>View / Print&lt;/v-list-item-title>
                &lt;/v-list-item>

                &lt;v-list-item :href="attachment.url" download>
                    &lt;v-list-item-title>
                        Download
                    &lt;/v-list-item-title>
                &lt;/v-list-item>

                &lt;v-list-item @click="saveFileToDocuments()">
                    &lt;v-list-item-title>Save to Documents&lt;/v-list-item-title>
                &lt;/v-list-item>

            &lt;/v-list>
        &lt;/hb-menu>
        &lt;hb-chip pointer @chip-click="showMoreAttachments = true">
            + <span v-pre>{{ exampleAttachments.length - 2 }}</span> more
        &lt;/hb-chip>
    &lt;/v-row>
    &lt;v-row no-gutters v-else style="margin-bottom:-10px;">
        &lt;hb-menu v-for="(attachment, i) in exampleAttachments" :key="'attachment_expanded' + i">
            &lt;template v-slot:menu-activator>
                &lt;hb-chip pointer prepend-icon="mdi-paperclip">
                    <span v-pre>{{ attachment.name }}</span>
                &lt;/hb-chip>
            &lt;/template>

            &lt;v-list>

                &lt;v-list-item :href="attachment.url" target="_blank">
                    &lt;v-list-item-title>View / Print&lt;/v-list-item-title>
                &lt;/v-list-item>

                &lt;v-list-item :href="attachment.url" download>
                    &lt;v-list-item-title>Download&lt;/v-list-item-title>
                &lt;/v-list-item>

                &lt;v-list-item @click="saveFileToDocuments()">
                    &lt;v-list-item-title>Save to Documents&lt;/v-list-item-title>
                &lt;/v-list-item>

            &lt;/v-list>
        &lt;/hb-menu>
    &lt;/v-row>
&lt;/hb-form>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="mb-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="snackbar"
            type="success"
            :description="description"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemChips",
        data: function() {
            return {
                wizards: ['Harry','Ron','Albus'],
                showMoreAttachments: false,
                exampleAttachments: [ 
                    {
                        name: "multiple_pdf_icon.png",
                        url: "/img/multiple_pdf_icon.png"
                    },
                    {
                        name: "cheque.png",
                        url: "/img/cc_icons/64/cheque.png"
                    },
                    {
                        name: "ach.png",
                        url: "/img/cc_icons/64/ach.png"
                    },
                    {
                        name: "credit_card.png",
                        url: "/img/cc_icons/64/credit_card.png"
                    },
                    {
                        name: "dollar.png",
                        url: "/img/cc_icons/64/dollar.png"
                    },
                ],
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'editable', type: 'boolean', default: 'false', description: 'Change this to true to turn on the delete icon for the chip.' },
                    { name: 'max-width', type: 'string', default: '480px', description: 'Sets the max width of the chip if needed. You usually will not have to use this prop.' },
                    { name: 'right-margin', type: 'boolean', default: 'true', description: 'Change this to false to remove the default 8px right margin of the chip if needed.' },
                    { name: 'vertical-margin', type: 'boolean', default: 'true', description: 'Change this to false to remove the default 4px top and bottom margin of the chip if needed.' },
                    { name: 'pointer', type: 'boolean', default: 'false', description: 'Change this to true to add a pointer cursor to the whole chip when it is hovered over.' },
                    { name: 'prepend-icon', type: 'string', default: 'undefined', description: 'Sets an optional prepended icon in front of the chip text. Accepts mdi-code format only, like "mdi-whatever-icon-name".' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@chip-click', description: 'Event for when the chip text/content/item area is clicked.' },
                    { name: '@click', description: 'Event for when the delete "x" icon is clicked and when editable prop is set to true.' },
                ],
                description: '',
                snackbar: false,
            };
        },
        methods: {
            handleChipClick(){
                this.description = 'The "@chip-click" event was initiated.';
                this.snackbar = true;
            },
            handleClick(){
                this.description = 'The "@click" event was initiated.';
                this.snackbar = true;
            },
            saveFileToDocuments(){
                this.description = "Attachment has been successfully saved to the contact's documents.";
                this.snackbar = true;
            },
        }
    }
</script>

<style scoped>

</style>
